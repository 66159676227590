<template>
  <v-container class="alert-box-wrapper">
    <v-alert class="alert-box" :class="{ 'show-alert': isError }" type="error" :value="true">{{ errorMessage }}</v-alert>
    <v-alert class="alert-box" :class="{ 'show-alert': isSuccess }" type="success" :value="true">{{ successMessage }}</v-alert>
  </v-container>

  <v-container class="change-password-page">
    <v-card-title class="text-h5">Zmień hasło</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submitChangePassword">

        <!-- Old Password Field -->
        <v-text-field
            label="Stare hasło"
            v-model="oldPassword"
            :type="passwordVisibility.oldPassword ? 'text' : 'password'"
            :append-inner-icon="passwordVisibility.oldPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="passwordVisibility.oldPassword = !passwordVisibility.oldPassword"
            required
        ></v-text-field>

        <!-- New Password Field -->
        <v-text-field
            label="Nowe hasło"
            v-model="newPassword"
            :type="passwordVisibility.newPassword ? 'text' : 'password'"
            :append-inner-icon="passwordVisibility.newPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="passwordVisibility.newPassword = !passwordVisibility.newPassword"
            :rules="passwordRules"
            required
        ></v-text-field>

        <!-- Confirm New Password Field -->
        <v-text-field
            label="Powtórz nowe hasło"
            v-model="confirmNewPassword"
            :type="passwordVisibility.confirmNewPassword ? 'text' : 'password'"
            :append-inner-icon="passwordVisibility.confirmNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="passwordVisibility.confirmNewPassword = !passwordVisibility.confirmNewPassword"
            :rules="confirmPasswordRules"
            required
        ></v-text-field>

        <v-btn type="submit" class="submit-button">Zmień hasło</v-btn>
      </v-form>
    </v-card-text>
  </v-container>
</template>


<script>
import axios from 'axios';
import { fieldRequiredRule, passwordPatternRule, passwordsMatchRule } from '@/utils/formValidators';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isError: false,
      errorMessage: '',
      isSuccess: false,
      successMessage: '',
      apiUrl: process.env.VUE_APP_API_URL,
      passwordVisibility: {
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      passwordRules: [
        fieldRequiredRule(),
        passwordPatternRule(),
      ],
      confirmPasswordRules: [
        fieldRequiredRule(),
        passwordsMatchRule(() => this.newPassword),
      ],
    };
  },
  watch: {
    oldPassword: 'hideAlerts',
    newPassword: 'hideAlerts',
    confirmNewPassword: 'hideAlerts',
  },
  methods: {
    hideAlerts() {
      this.isError = false;
      this.isSuccess = false;
    },
    async submitChangePassword() {
      const form = new FormData();
      form.append('old_password', this.oldPassword);
      form.append('new_password', this.newPassword);

      if (this.newPassword !== this.confirmNewPassword) {
        this.isError = true;
        this.errorMessage = 'Nowe hasła nie są takie same.';
        return;
      }
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.post(this.apiUrl + 'change-password/', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          this.isSuccess = true;
          this.successMessage = 'Hasło zostało zmienione.';
        }
      } catch (error) {
        this.isError = true;
        this.errorMessage = 'Wystąpił błąd podczas zmiany hasła.';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.change-password-page {
  max-width: 400px; /* Match the LoginForm.vue width */
  margin-top: 32px;
  padding: 20px; /* Padding inside the form container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect around the form */
  border-radius: 8px; /* Rounded corners */
  background: white; /* White background */
}

.change-password-page h2 {
  text-align: center; /* Center align the heading */
  margin-bottom: 20px; /* Space below the heading */
}

.change-password-page .form-group {
  margin-bottom: 15px; /* Space below each form group */
}

.change-password-page .form-group label {
  display: block; /* Ensure the label is on its own line */
}

.change-password-page .form-group input {
  width: 100%; /* Full width input fields */
  padding: 10px; /* Padding inside the input fields */
  margin-top: 5px; /* Space between the label and the input field */
}

.change-password-page .submit-button {
  width: 100%; /* Full width button */
  padding: 10px 20px; /* Padding inside the button */
  margin-top: 10px; /* Space above the button */
  background-color: black; /* Black background to match the LoginForm.vue button */
  color: white; /* White text color */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  font-weight: bold;
}

.change-password-page .message {
  color: green;
  text-align: center;
  margin-top: 15px;
}

.change-password-page .error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}
</style>
